.uds-quote-image-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  align-items: flex-end;
  color: $uds-color-base-white !important;
  display: flex;
  flex-direction: row;
  height: auto;
  justify-content: center;
  max-width: 1920px;
  padding: 2rem;
  width: 100%;

  @media screen and (max-width: $uds-breakpoint-sm) {
    min-height: 512px;
    padding: 24px;
    width: 100%;
    margin: 0;
    .container {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  &-container {
    max-width: 384px;
    @media screen and (max-width: $uds-breakpoint-sm) {
      width: 100%;

      .uds-blockquote {
        font-size: 1rem;
      }
    }
  }
}
