h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  // font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  text-align: $heading-text-align;
  opacity: $heading-opacity;
  margin: $heading-margin 0;
  line-height: $heading-line-height;
}

// If there is a heading between two paragraphs,
// there needs to be more space above the heading than below it to ensure that content is chunked appropriately and remains legible.
// The following two selectors combined target heading elements surrounded by p elements
p + h1,
p + h2,
p + h3,
p + h4,
p + h5,
p + h6,
p + .h1,
p + .h2,
p + .h3,
p + .h4,
p + .h5,
p + .h6 {
  margin-top: $uds-size-spacing-4;
}

p:last-of-type + h1,
p:last-of-type + h2,
p:last-of-type + h3,
p:last-of-type + h4,
p:last-of-type + h5,
p:last-of-type + h6,
p:last-of-type + .h1,
p:last-of-type + .h2,
p:last-of-type + .h3,
p:last-of-type + .h4,
p:last-of-type + .h5,
p:last-of-type + .h6{
  margin-top: $heading-margin;
}

h1, .h1 {
  font-size: $heading-one-font-size;
  line-height: $heading-one-line-height;
  letter-spacing: $heading-one-letter-spacing;

  &.article {
    font-size: $heading-one-article-font-size;
    line-height: $heading-one-article-line-height;
    letter-spacing: $heading-one-article-letter-spacing;
  }

  @media (max-width: 577px) {
    &,
    &.article {
      font-size: $heading-one-mobile-font-size;
      line-height: $heading-one-mobile-line-height;
      letter-spacing: $heading-one-mobile-letter-spacing;
    }
  }
}

h2, .h2 {
  font-size: $heading-two-font-size;
  line-height: $heading-two-line-height;
  letter-spacing: $heading-two-letter-spacing;

  @media (max-width: 577px) {
    font-size: $heading-two-mobile-font-size;
    line-height: $heading-two-mobile-line-height;
    letter-spacing: $heading-two-mobile-letter-spacing;
  }
}

h3, .h3 {
  font-size: $heading-three-font-size;
  line-height: $heading-three-line-height;
  letter-spacing: $heading-three-letter-spacing;
}

h4, .h4 {
  font-size: $heading-four-font-size;
  line-height: $heading-four-line-height;
  letter-spacing: $heading-four-letter-spacing;
}

h5, .h5 {
  font-size: $heading-five-font-size;
  line-height: $heading-five-line-height;
  letter-spacing: $heading-five-letter-spacing;
}

h1 span[class^='highlight-'],
.h1 span[class^='highlight-'],
h2 span[class^='highlight-'],
.h2 span[class^='highlight-'],
h3 span[class^='highlight-'],
.h3 span[class^='highlight-'],
h4 span[class^='highlight-'],
.h4 span[class^='highlight-'] {
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
}

h1 span.highlight,
.h1 span.highlight,
h2 span.highlight,
.h2 span.highlight {
  &-gold {
    @include heading-highlight-large-box-shadow(
        $heading-highlight-gold-background-color,
        $heading-highlight-gold-text-color
    );
  }

  &-black {
    @include heading-highlight-large-box-shadow(
        $heading-highlight-black-background-color,
        $heading-highlight-black-text-color
    );
  }

  &-white {
    @include heading-highlight-large-box-shadow(
        $heading-highlight-white-background-color,
        $heading-highlight-white-text-color
    );
  }
}

h3 span.highlight,
.h3 span.highlight,
h4 span.highlight,
.h4 span.highlight, {
  &-gold {
    @include heading-highlight-small-box-shadow(
        $heading-highlight-gold-background-color,
        $heading-highlight-gold-text-color
    );
  }

  &-black {
    @include heading-highlight-small-box-shadow(
        $heading-highlight-black-background-color,
        $heading-highlight-black-text-color
    );
  }

  &-white {
    @include heading-highlight-small-box-shadow(
        $heading-highlight-white-background-color,
        $heading-highlight-white-text-color
    );
  }
}
