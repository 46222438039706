$section-image-height: 512px;

div.uds-card-image-and-content {
  display: flex;

  &-image-container {
    background-size: cover;
    background-position: top;
    height: $section-image-height;
    position: absolute;
    width: 100%;
  }

  &-content-container {
    display: grid;
    grid-template-areas:
      "title ."
      "content card"
    ;
    grid-template-rows: $section-image-height auto;
    grid-template-columns: 55% auto;
    align-items: end;
    position: relative;

    & > &-headline {
      grid-area: title;
      font: normal normal bold $uds-size-font-xxxl Arial;
      padding: $uds-size-spacing-4;
      color: $uds-color-base-white;
      margin: unset;
    }

    .content {
      grid-area: content;
      align-self: start;
      padding: $uds-size-spacing-4;
    }

    .card {
      margin: calc(-1 * #{$card-image-top-height} - 1px) 0 0 2rem;
      grid-area: card;
      height: unset;
    }
  }

  @media only screen and (max-width: $uds-breakpoint-lg) {
    &-content-container {
      grid-template-areas:
        "title"
        "content"
        "card";
      grid-template-columns: auto;

      .card {
        width: auto;
        margin: 0 $uds-size-spacing-4;
      }
    }
  }

  @media only screen and (max-width: $uds-breakpoint-sm) {
    & {
      &-image-container {
        height: $uds-size-spacing-32;
      }
      &-content-container {
        grid-template-rows: $uds-size-spacing-32 auto;

        & > &-headline {
          font: normal normal bold 2rem Arial;
          padding: $uds-size-spacing-4;
        }
      }
    }
  }
}

