// Bootstrap form field variables to override.

$input-placeholder-color: $uds-color-base-gray-5;
$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-radius-lg: 0;

$input-height: auto;
$input-height-sm: auto;
$input-height-lg: auto;

$form-validation-states: () !default;
$form-validation-states: map-merge(
  (
    'valid': (
      'color': #446d12,
      'icon': none,
    ),
    'invalid': (
      'color': #b72a2a,
      'icon': none,
    ),
  ),
  $form-validation-states
);

$input-padding-y: 0.5rem;
$input-padding-y-sm: 0.5rem;
$input-padding-y-lg: 0.5rem;

$input-padding-x: 0.5rem;
$input-padding-x-sm: 0.5rem;
$input-padding-x-lg: 0.5rem;
