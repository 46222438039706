$uds-card-height: 466px;

.uds-card-and-image {
  aspect-ratio: 16 / 9;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: row;
  height: $uds-card-height;
  max-width: 1920px;
  padding: $uds-size-spacing-6 $uds-size-spacing-12;
  width: 100%;

  &-right {
    flex-direction: row-reverse;
    .card-and-image-align {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  @media screen and (max-width: $uds-breakpoint-sm) {
    flex-direction: column;
    height: auto;
    justify-content: flex-end;
    min-height: 720px;
    padding: 24px;
    width: 100%;
    margin: 0;
    .container {
      padding-left: $uds-size-spacing-1;
      padding-right: $uds-size-spacing-1;
    }
  }

  &-container {
    max-width: 384px;
    .card .card-header {
      padding-bottom: $uds-size-spacing-2 !important;
    }
    .card {
      max-height: calc(#{$uds-card-height} - #{$uds-size-spacing-8});

      .card-icon-top {
        // the negative value of margin bottom is a simple workaround, this allows to respect the space between the title and the icon if both elements are present or the H1 and the top border of the card if the icon is not present
        margin: $uds-size-spacing-3 auto -0.5rem $uds-size-spacing-3;
      }

      .card-body {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 0;
        margin-bottom: $uds-size-spacing-3;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;

        p {
          margin-bottom: 0;
        }
      }

      .card-buttons {
        .card-button {
          padding-bottom: 0;
        }
      }
    }
    @media screen and (max-width: $uds-breakpoint-sm) {
      width: 100%;
    }
  }
}
