// ASU Brand Standard Colors
// Reference: https://hub.asu.edu/brand-hq/brand-standards/color-palette

$white: $uds-color-base-white;
$gray-1: $uds-color-base-gray-1;
$gray-2: $uds-color-base-gray-2;
$gray-3: $uds-color-base-gray-3;
$gray-4: $uds-color-base-gray-4;
$gray-5: $uds-color-base-gray-5;
$gray-6: $uds-color-base-gray-6;
$gray-7: $uds-color-base-gray-7;

$body-color: $uds-color-base-gray-7;

$grays: ();

$grays: map-merge(
  (
    '1': $gray-1,
    '2': $gray-2,
    '3': $gray-3,
    '4': $gray-4,
    '5': $gray-5,
    '6': $gray-6,
    '7': $gray-7,
  ),
  $grays
);

$gold: $uds-color-base-gold;
$maroon: $uds-color-base-maroon;
$dark: $uds-color-base-gray-7;
$light: $uds-color-base-gray-2;
$blue: $uds-color-base-blue;
$green: $uds-color-base-green;
$orange: $uds-color-base-orange;

$bluefocus: $uds-color-base-bluefocus;
$darkgold: $uds-color-base-darkgold;
$darkmaroon: $uds-color-base-darkmaroon;

$colors: () !default;

$colors: map-merge(
  (
    'gold': $gold,
    'maroon': $maroon,
    'blue': $blue,
    'green': $green,
    'orange': $orange,
    'white': $white,
    'bluefocus': $bluefocus,
    'darkgold': $darkgold,
    'darkmaroon': $darkmaroon,
  ),
  $colors
);

$primary: $maroon;
$secondary: $gold;
$danger: $uds-color-alerts-error;
$warning: $uds-color-alerts-warning;
$success: $uds-color-alerts-success;
$info: $uds-color-alerts-info;

$theme-colors: ();

$theme-colors: map-merge(
  (
    'gold': $gold,
    'maroon': $maroon,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'gray': $gray-4,
    'dark': $dark,
    'gray-1': $gray-1,
    'gray-2': $gray-2,
    'gray-3': $gray-3,
    'gray-4': $gray-4,
    'gray-5': $gray-5,
    'gray-6': $gray-6,
    'gray-7': $gray-7,
  ),
  $theme-colors
);

// Links
//
// Copied from vanilla Bootstrap _variables.scss to override them
//
// Unsure if the link hover darken and stretched link variables will cause issues for us
// Commented out for now, until we determine if we need to override.

$link-color: $maroon;
$link-decoration: underline;
$link-hover-color: $maroon;
$link-hover-decoration: none;

:focus {
  outline: 0;
  box-shadow: 0 0 8px $uds-color-base-bluefocus !important;
}

// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
// $emphasized-link-hover-darken-percentage: 15%;

// $stretched-link-pseudo-element: after;
// $stretched-link-z-index: 1;

$card-cap-bg: rgba($white, 0.03);

$nav-tabs-link-color: $dark;
$nav-tabs-border-width: 1px;
$nav-tabs-border-color: $gray-3;
$nav-tabs-border-radius: 0;
$nav-tabs-link-active-color: $maroon;
$nav-tabs-link-active-border-color: $maroon;
