.parallax-container {
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    width: 100%;
  }

  .parallax-container-content {
    width: 600px;
    position: absolute;
    top: 150px;
    background-color: white;
    padding: $uds-size-spacing-6;

    &.uds-card-and-image {
      background-color: transparent;
    }

    h2 {
      margin: 0;
    }

    p {
      margin: $uds-size-spacing-4 0 $uds-size-spacing-3 0;
    }

    @media screen and (max-width: $uds-breakpoint-md) {
      padding: $uds-size-spacing-2;
      width: 450px;

      p {
        margin: $uds-size-spacing-2 0 $uds-size-spacing-1 0;
      }
    }

    .btn-maroon {
      margin-right: $uds-size-spacing-2;
    }
  }
}

.carve-your-path {
  height: 700px;

  .parallax-container-content {
    height: 400px;
    left: 75px;

    @media screen and (max-width: $uds-breakpoint-md) {
      left: 25px;
      height: 450px;
    }
    .btn-gold {
      margin-top: $uds-size-spacing-2;
    }

    .btn-maroon {
      display: block;
      margin-top: $uds-size-spacing-2;
    }

    @media screen and (min-width: $uds-breakpoint-md) {
      .btn-maroon {
        display: inline-block;
        margin-right: $uds-size-spacing-2;
        margin-top: 0;
      }
    }
  }
}
.asu-for-you {
  height: 800px;
  background-size: cover;
  background-position: center;
  background-image: url('https://ux-annual-report.ws.asu.edu/sites/all/libraries/Prototype/images/asuForYouBG.png');

  .parallax-container-content {
    height: 500px;
    right: 75px;
    @media screen and (max-width: $uds-breakpoint-md) {
      right: 25px;
      height: 450px;
    }
  }
}
