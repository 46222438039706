html,
body {
  height: 100%;
  margin: 0;
}

body {
  display: flex;
  flex-direction: column;
}

#app {
  flex: 1 0 auto;
}

#footer {
  flex-shrink: 0;
  margin-top: 20%;
}
