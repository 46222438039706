/*--------------------------------------------------------------
# UDS Grid Links

1. Mobile
2. Desktop modifiers
--------------------------------------------------------------*/

@mixin like-an-h4 {
  font-size: $uds-size-font-large;
  letter-spacing: -0.01875rem;
  font-weight: 700;
}

/*--------------------------------------------------------------
1. Mobile
--------------------------------------------------------------*/

.uds-grid-links {
  grid-template-columns: 1fr;
  display: grid;
  grid-template-rows: auto;
  column-gap: $uds-size-spacing-3;
  row-gap: $uds-size-spacing-3;

  a {
    display: flex;
    @include like-an-h4;
    border: 1px solid $uds-color-base-gray-3;
    color: $uds-color-base-gray-7;
    background-color: $uds-color-base-white;
    padding: $uds-size-spacing-3;
    text-decoration: none;

    svg {
      margin: calc(#{$uds-size-spacing-1} / 2) $uds-size-spacing-1 0 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &.text-gold a {
    color: $uds-color-base-gold;
    background-color: $uds-color-base-gray-7;
  }

  &.text-white a {
    color: $uds-color-base-white;
    background-color: $uds-color-base-gray-7;
  }
}

/*--------------------------------------------------------------
2. Desktop modifiers
--------------------------------------------------------------*/

@include media-breakpoint-up(md) {
  .uds-grid-links.two-columns,
  .uds-grid-links.three-columns,
  .uds-grid-links.four-columns {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include media-breakpoint-up(lg) {
  .uds-grid-links.three-columns,
  .uds-grid-links.four-columns {
    grid-template-columns: repeat(3, 1fr);
  }
}

@include media-breakpoint-up(xl) {
  .uds-grid-links {
    &.four-columns {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
