.uds-anchor-menu {
  background-color: white;
  border-bottom: 1px solid $uds-color-base-gray-3;
  z-index: 100;

  &-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }

  h4 {
    font-size: 1rem;
    padding: 0 !important;

    &:hover {
      transform: none;
    }

    svg {
      margin-left: $uds-size-spacing-1;
      margin-right: $uds-size-spacing-1;
      margin-top: 0.35rem;
      transform: rotate(0deg);
    }

    &[aria-expanded='true'] {
      svg,
      i {
        transform: rotate(180deg);
      }
    }
  }

  .card-body {
    padding-top: $uds-size-spacing-3;
  }

  nav {
    flex-direction: column;
  }

  .nav-link {
    color: $uds-color-base-gray-7;
    padding: $uds-size-spacing-3 0;
    padding-left: 0.5rem;
    text-align: start;
    font-weight: 400;

    &:not(:last-child) {
      border-bottom: 1px solid $uds-color-base-gray-3;
    }

    &:hover,
    &.active {
      margin-bottom: 0;
      border-bottom: $uds-size-spacing-1 solid $uds-color-base-gold;
    }

    &:visited {
      color: $uds-color-base-gray-7;
    }

    &:focus {
      box-shadow: 0px 0px 0px 2px $uds-color-base-gray-7 !important;
    }

    svg {
      width: 2rem !important;
      margin-right: $uds-size-spacing-1;
      margin-left: -0.5rem;
    }
  }
}

@mixin uds-anchor-menu-expanded {
  border-bottom: 1px solid $uds-color-base-gray-3;

  .uds-anchor-menu-wrapper {
    flex-direction: row;
  }

  h4 {
    margin: auto 0;
    font-size: 1.25rem;

    svg {
      display: none;
    }
  }

  nav {
    flex-direction: row;
  }

  .nav-link {
    border-bottom: $uds-size-spacing-1 solid transparent;
    padding: $uds-size-spacing-3 $uds-size-spacing-2 $uds-size-spacing-2
      $uds-size-spacing-2;
    margin-left: 8px;

    &:hover,
    &.active {
      margin-bottom: 0;
      border-bottom: $uds-size-spacing-1 solid $uds-color-base-gold;
    }
  }

  .card {
    border: 0;
    padding: 0;
  }

  .collapse {
    display: block;
    height: auto !important;
    visibility: visible;

    &:not(.show) {
      display: block;
    }
  }

  .collapsing {
    position: relative;
    height: unset !important;
    overflow: hidden;
  }
}

@include media-breakpoint-up(sm) {
  .uds-anchor-menu.uds-anchor-menu-expanded-sm {
    @include uds-anchor-menu-expanded;
  }
}

@include media-breakpoint-up(md) {
  .uds-anchor-menu.uds-anchor-menu-expanded-md {
    @include uds-anchor-menu-expanded;
  }
}

@include media-breakpoint-up(lg) {
  .uds-anchor-menu.uds-anchor-menu-expanded-lg {
    @include uds-anchor-menu-expanded;
  }
}

@include media-breakpoint-down(lg) {
  .uds-anchor-menu h4 {
    display: flex;
    justify-content: space-between;
  }
}

@include media-breakpoint-up(xl) {
  .uds-anchor-menu.uds-anchor-menu-expanded-xl {
    @include uds-anchor-menu-expanded;
  }
}
