.uds-image-based-card {
  border: none;
  position: relative;
  overflow: hidden;

  .card-title {
    margin-bottom: 1.5rem;
  }

  img {
    object-fit: cover;
    object-position: center;
    height: inherit;
    width: inherit;
  }

  .card-img-overlay {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-image: linear-gradient(
      180deg,
      rgba(25, 25, 25, 0) 0%,
      rgba(25, 25, 25, 0.7882352941) 100%
    );
    z-index: 20;
    padding: 1.5rem;
  }

  &.portrait {
    &-sm,
    &-md {
      width: 100%;
      // the aspect ratio of the card is determined by the type of layout to be used to display the image
      img {
        aspect-ratio: 9/16;
      }
      h2 {
        font-size: 2.25rem;
      }
    }
    &-sm {
      // card size is determined by the aspect ratio of the image to be displayed
      height: 450px;
      @include media-breakpoint-up(md) {
        width: 255px;
      }
    }
    &-md {
      height: 620px;
      @include media-breakpoint-up(md) {
        width: 350px;
      }
      .card-img-overlay {
        padding-bottom: 3rem;
      }
    }
  }

  &.landscape {
    &-sm,
    &-md,
    &-lg {
      width: 100%;
      // the aspect ratio of the card is determined by the type of layout to be used to display the image
      img {
        aspect-ratio: 16/9;
      }
    }
    &-sm {
      height: 144px;
      h2 {
        font-size: $uds-size-font-xl;
      }
      @include media-breakpoint-up(md) {
        width: 255px;
      }
    }
    &-md {
      height: 197px;
      h2 {
        font-size: $uds-size-font-xl;
      }
      @include media-breakpoint-up(md) {
        width: 350px;
      }
      .card-img-overlay {
        padding-bottom: 2rem;
      }
    }
    &-lg {
      height: 304px;
      @include media-breakpoint-up(md) {
        width: 540px;
      }
      h2 {
        font-size: 36px;
      }
      .card-img-overlay {
        padding: 2.5rem 2rem;
      }
    }
  }

  &.square {
    &-sm,
    &-md,
    &-lg {
      width: 100%;
      // the aspect ratio of the card is determined by the type of layout to be used to display the image
      img {
        aspect-ratio: 1/1;
      }
      .card-img-overlay {
        padding: 2rem;
      }
      h2 {
        font-size: 2.25rem;
      }
    }
    &-sm {
      height: 255px;
      @include media-breakpoint-up(md) {
        width: 255px;
      }
      h2 {
        font-size: $uds-size-font-xl;
      }
    }
    &-md {
      height: 350px;
      @include media-breakpoint-up(md) {
        width: 350px;
      }
    }
    &-lg {
      height: 540px;
      @include media-breakpoint-up(md) {
        width: 540px;
      }
      .card-img-overlay {
        padding: 3rem;
      }
    }
  }
}
