// Typography
//
// Font, line-height, and color for body text, headings, and more.

// ASU Brand Standard Fonts
// Reference: https://brandguide.asu.edu/Elements-of-the-brand/Fonts

$font-family-sans-serif: $uds-font-family-base;
$font-family-base: $font-family-sans-serif;

$font-weight-lighter: $uds-font-weight-lighter;
$font-weight-light: $uds-font-weight-light;
$font-weight-normal: $uds-font-weight-normal;
$font-weight-bold: $uds-font-weight-bold;
$font-weight-bolder: $uds-font-weight-bolder;

// Body Font Color
$body-font-color: $dark;

body {
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
  color: $body-font-color;
}

// Text formatting
.text-white {
  color: $light !important;
}

.text-underline {
  text-decoration: underline;
}

.text-capitalize {
  text-transform: capitalize !important;
}

// No underlines allowed.
u,
ins {
  text-decoration-line: none;
  font-style: italic;
}

// No underlines allowed.
abbr {
  text-decoration-line: none !important;
  text-decoration-style: none !important;
  text-decoration-color: initial !important;
}

// Correct <strong> to weight 700. (BS4 default is 900.)
// Bootstrap _reset.sass overrides this because it happens after this file is loaded.
// Important tag required.
b,
strong {
  font-weight: $uds-font-weight-bold !important;
}

