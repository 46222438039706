hr {
  border-top: 0;
  height: 1px;
  margin: $uds-size-spacing-6 0;
  background-color: $uds-color-base-gray-3;
  opacity: 1;

  &.copy-divider {
    height: $uds-size-spacing-1;
    background-color: $uds-color-base-gold;
    max-width: $uds-size-spacing-32;
  }
}
