.content-section {
  $transform-scale-value: 1.04;

  @mixin regular-transition {
    transition: all 1s cubic-bezier(0.19, 1, 0.19, 1);
  }

  height: 496px;
  width: 100%;
  position: relative;

  .image-holder {
    overflow: hidden;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      @include regular-transition;
      z-index: 10;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      background-image: linear-gradient(
        180deg,
        rgba(25, 25, 25, 0.15) 0%,
        rgba(25, 25, 25, 0.25) 50%,
        rgba(25, 25, 25, 1) 100%
      );
    }
  }

  .content-holder {
    position: absolute;
    bottom: $uds-size-spacing-4;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: auto;
    z-index: 20;

    .content-bg {
      h3 {
        font-size: $uds-size-font-xxl;
        line-height: 2.125rem;
        padding: $uds-size-spacing-3 $uds-size-spacing-3 $uds-size-spacing-1;
        margin: 0;
      }
      .hidden-details {
        .long-text {
          display: none;
          margin-bottom: 0;
        }
        a.btn {
          margin: $uds-size-spacing-3 $uds-size-spacing-3 0;
        }
      }
    }
  }

  @media screen and (min-width: $uds-grid-container-max-width-md) {
    width: 384px;
    height: 600px;

    .image-holder {
      @include regular-transition;
    }
    .content-holder {
      width: calc(100% - #{$uds-size-spacing-8});
      left: $uds-size-spacing-4;
      .content-bg {
        @include regular-transition;
        h3 {
          font-size: 2.5rem;
          line-height: 2.75rem;
        }
        .hidden-details {
          @include regular-transition;
          transition-duration: 0.5s;
          max-height: 0;
          overflow: hidden;
          opacity: 0;
          .long-text {
            display: block;
          }
        }
      }
    }
    &:hover,
    &:focus-within {
      .image-holder {
        cursor: pointer;
        transform: scale($transform-scale-value);

        &::before {
          background-image: linear-gradient(
            180deg,
            rgba(25, 25, 25, 0) 0%,
            rgba(25, 25, 25, 0.15) 50%,
            rgba(25, 25, 25, 1) 100%
          );
        }
      }

      .image-holder:before {
        transform: scale($transform-scale-value);
      }

      .content-holder {
        .content-bg {
          background-color: $uds-color-background-white;
          h3 {
            color: $uds-color-base-gray-7;
          }
          .hidden-details {
            max-height: 300px;
            opacity: 1;
            .long-text {
              color: $uds-color-base-gray-7;
              padding: $uds-size-spacing-2 $uds-size-spacing-3
                $uds-size-spacing-3;
            }
            a.btn {
              margin-top: 0;
              margin-bottom: $uds-size-spacing-3;
              font-size: 0.875rem;
              line-height: 1.5rem;
            }
          }
        }
      }
    }
  }
}
