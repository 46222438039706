.uds-tabbed-panels {
  box-shadow: inset 0px -2px 0px 0px $uds-color-base-gray-4;
  flex-wrap: inherit;
  overflow: hidden;
  position: relative;
  -webkit-overflow-scrolling: touch;
  white-space: nowrap;

  .scroll::-webkit-scrollbar {
    display: none;
  }

  &:hover span.carousel-control-prev-icon,
  &:hover span.carousel-control-next-icon {
    @media screen and (min-width: $uds-breakpoint-lg) {
      opacity: 1;
    }
  }

  .nav-tabs {
    display: flex;
    flex-wrap: inherit;
    left: 0px;
    position: relative;
    transition: all 0.25s ease 0s;
    white-space: nowrap;
    overflow-x: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none; // to hide scrollbar in IE and Edge
    scrollbar-width: none; // to hide scrollbar in Firefox
    &::-webkit-scrollbar {
      display: none; // to hide scrollbar In Chrome, Safari, Opera, and other WebKit-based browsers
    }

    .nav-link {
      color: #000;
      padding: 0.2em 0.8em;
      border-right: 0;
      border-left: 0;
      border-top: 0;
      text-decoration: none;
      font-weight: bold;
      font-size: 24px;
      margin: 0.2em 0.2em 0 0.2em;
    }

    .nav-link.active {
      background-color: transparent;
      border-bottom: 8px solid $uds-color-base-maroon;
    }

    .nav-link:hover,
    .nav-link:focus {
      color: $uds-color-base-maroon;
    }
  }

  &-dark {
    .nav-tabs {
      .nav-link {
        color: $uds-color-base-gray-1;
      }

      .nav-link.active {
        color: $uds-color-brand-gold;
        background-color: transparent;
        border-bottom: 8px solid $uds-color-brand-gold;
      }

      .nav-link:hover,
      .nav-link:focus {
        color: $uds-color-brand-gold;
      }
    }

    &:hover span.carousel-control-prev-icon,
    &:hover span.carousel-control-next-icon {
      @media screen and (min-width: $uds-breakpoint-lg) {
        opacity: 1;
      }
    }
  }
}

.scroll-control {
  &-prev {
    outline: none;
    border: none;
    width: 80px;
    position: absolute;
    height: 100%;
    top: 0;
    background: linear-gradient(
      90deg,
      rgba(25, 25, 25, 0.25) 0%,
      rgba(25, 25, 25, 0) 100%
    );
    left: 0;

  }

  &-next {
    width: 80px;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    background: linear-gradient(
      90deg,
      rgba(25, 25, 25, 0) 0%,
      rgba(25, 25, 25, 0.25) 100%
    );

    span.carousel-control-next-icon {
      margin: 0 12px 0 42px;
    }
  }

  &-prev span.carousel-control-prev-icon,
  &-next span.carousel-control-next-icon {
    background-size: 60% 60%;
    display: block;
    opacity: 1;
    padding: 12px;
    position: relative;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background-color: $uds-color-base-gray-1;
    border: solid 1px $uds-color-base-gray-3;
    border-radius: 100%;
    color: #000;
  }
  @media screen and (max-width: $uds-breakpoint-md) {
    &-prev,
    &-next {
      width: 48px;
    }
    &-next,
    &-prev {
    span.carousel-control-next-icon,
    span.carousel-control-prev-icon
    {
      margin: 0px 12px 0px 8px;
    }
    span.carousel-control-prev-icon {
      margin-left: 0px;
    }
  }
  }
}

.tab-content {
  overflow-x: hidden;
  word-break: break-word;
  padding-top: $uds-size-spacing-4;
  padding-bottom: $uds-size-spacing-4;
  margin-bottom: $uds-size-spacing-4;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' data-fa-i2svg=''><path fill='currentColor' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'></path></svg>");
  background-position: 80% 50%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' data-fa-i2svg=''><path fill='currentColor' d='M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z'></path></svg>");
  background-position: 60% 50%;
}
