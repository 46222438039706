.uds-video {
  &-with-caption {
    border: 1px solid $uds-color-base-gray-3;

    figure {
      margin: 0;
      display: flex;
      align-items: center;

      figcaption {
        color: $uds-color-base-gray-5;
        font-size: $uds-size-font-tiny;
        margin: $uds-size-spacing-1 $uds-size-spacing-2 $uds-size-spacing-2
          $uds-size-spacing-2;
      }
    }
  }

  &-btn-play.btn.btn-circle.btn-circle-large {
    background-color: white !important;
    z-index: 2;
    opacity: 0.7;
    width: 104px !important;
    height: 104px !important;
    font-size: $uds-size-spacing-5 !important;

    &:hover {
      opacity: 1;
      transform: scale(1.15);
    }

    .svg-inline--fa.fa-w-14 {
      margin-left: 5px; // magic number to make play button appear more centered
    }

    @media (max-width: $uds-breakpoint-sm) {
      width: 64px !important;
      height: 64px !important;
      font-size: $uds-size-spacing-4 !important;
    }
  }

  &-container {
    video {
      height: auto;
      width: 100%;
      z-index: 0;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }

  &-player {
    position: relative;
    height: fit-content;
    &.youtube-video {
      padding-bottom: 56.25%; /* 16:9 */
    }
  }

  &-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: transparent
      linear-gradient(180deg, #19191900 0%, #191919c9 100%) 0% 0% no-repeat
      padding-box;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: $uds-size-spacing-1;
    z-index: 1;

    &:hover button.uds-video-btn-play {
      opacity: 1;
      transform: scale(1.15);
    }
  }
}
