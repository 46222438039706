.uds-card-arrangement {
  display: flex;
  flex-direction: column;
  max-width: 1400px;

  .card-horizontal {
    &.card-event {
      .card-img-top {
        max-height: 500px;
      }
    }
  }

  & > &-content-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    & > h2 {
      font: normal normal bold 2.5rem Arial;
      padding-right: $uds-size-spacing-6;
    }

    & > button {
      margin-left: auto;
    }

    @media screen and (max-width: $uds-breakpoint-md) {
      flex-direction: column;
      align-items: flex-start;
      & > h2 {
        padding-right: 0;
      }
      & > button {
        margin-left: unset;
        margin-right: auto;
      }
    }
  }

  & > &-card-container {
    column-gap: $uds-size-spacing-3;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(auto-fill, 1fr);
    margin-top: $uds-size-spacing-3;

    &.auto-arrangement {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: row;
      row-gap: $uds-size-spacing-3;
      &.three-columns {
        grid-template-columns: repeat(3, 1fr);
      }
      &.four-columns {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    @media screen and (max-width: $uds-breakpoint-sm) {
      grid-auto-flow: row;
      row-gap: $uds-size-spacing-2;
    }
  }

  &-vertical {
    flex-direction: row;

    & > .uds-card-arrangement-content-container {
      align-items: flex-start;
      justify-content: start;
      flex: 1;
      flex-direction: column;

      & > button {
        margin: initial;
      }
    }

    & > .uds-card-arrangement-card-container {
      flex: 2;
      row-gap: $uds-size-spacing-3;
      display: grid;
      grid-auto-flow: row;
      grid-template-rows: repeat(auto-fill, 1fr);
      margin-left: $uds-size-spacing-3;
    }
  }

  @media screen and (max-width: $uds-breakpoint-xl) {
    & > &-card-container {
      &.auto-arrangement {
        &.four-columns {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }

  @media screen and (max-width: $uds-breakpoint-lg) {
    & > &-card-container {
      &.auto-arrangement {
        &.four-columns,
        &.three-columns {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  @media screen and (max-width: $uds-breakpoint-sm) {
    flex-direction: column;
    & > &-card-container {
      &.auto-arrangement {
        grid-template-columns: 1fr;
        &.four-columns,
        &.three-columns {
          grid-template-columns: 1fr;
        }
      }
    }
    &-vertical {
      & > .uds-card-arrangement-card-container {
        margin-left: 0;
      }
    }
  }
}
